'use client'

import type { CompareSliderStoryblok } from '@vendure/codegen/storyblok'
import { GradientCard } from '@vendure/components/shared/client'
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from 'react-compare-slider'

interface ICompareSlider {
  blok: CompareSliderStoryblok
}

export function CompareSlider({ blok }: ICompareSlider) {
  return (
    <GradientCard>
      <ReactCompareSlider
        itemOne={
          <ReactCompareSliderImage
            src={blok.image1?.filename}
            alt={blok.image1?.alt}
          />
        }
        itemTwo={
          <ReactCompareSliderImage
            src={blok.image2?.filename}
            alt={blok.image2?.alt}
          />
        }
      />
    </GradientCard>
  )
}
