'use client'

import type { VideoStoryblok } from '@vendure/codegen/storyblok'
import { useMemo } from 'react'
import FilePlayer from 'react-player/file'
import YouTubePlayer from 'react-player/youtube'

interface IVideo {
  blok: VideoStoryblok
}

export function Video({ blok }: IVideo) {
  const defaultProps = {
    controls: true,
    width: '100%',
    height: '100%',
  }

  const player = useMemo(() => {
    switch (blok.type) {
      case 'file':
        return (
          <FilePlayer
            controls={true}
            width="100%"
            height="100%"
            url={blok.file?.filename}
            playing={false}
            muted={true}
            loop={true}
            config={{
              attributes: {
                width: '100%',
              },
            }}
          />
        )
      case 'youtube':
      default:
        return (
          <YouTubePlayer
            {...defaultProps}
            url={blok.url}
            config={{
              embedOptions: {
                width: '100%',
                height: '100%',
              },
            }}
          />
        )
    }
  }, [blok])

  return <div className="my-5 aspect-video">{player}</div>
}
