'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'
import type { AccordionStoryblok } from '@vendure/codegen/storyblok'
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Accordion as AccordionWrapper,
} from '@vendure/components/ui'

import { Heading, RichText } from '@vendure/components/shared/client'

interface IAccordion {
  blok: AccordionStoryblok
}

export function Accordion({ blok }: IAccordion) {
  return (
    <AccordionWrapper
      type="single"
      collapsible
      {...storyblokEditable(blok)}
    >
      {blok.items?.map((item) => {
        return (
          <AccordionItem
            key={item._uid}
            value={item._uid}
          >
            <Heading className="text-left">
              <AccordionTrigger className="py-4 text-lg font-semibold text-white">
                {item.heading}
              </AccordionTrigger>
            </Heading>
            <AccordionContent className="pb-4">
              {item.text && (
                <RichText
                  containerProps={{ className: 'leading-relaxed' }}
                  document={item.text}
                />
              )}
              {item.content?.map((item) => {
                return (
                  <StoryblokComponent
                    key={item._uid}
                    blok={item}
                  />
                )
              })}
            </AccordionContent>
          </AccordionItem>
        )
      })}
    </AccordionWrapper>
  )
}
