'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import type { PageHeroStoryblok } from '@vendure/codegen/storyblok'
import {
  ActionButton,
  PreTitle,
  RichText,
  TitleWithAccent,
  useNavContext,
} from '@vendure/components/shared/client'
import DotPattern from '@vendure/components/ui/magicui/dot-pattern'
import { cn } from '@vendure/helpers'

export interface IPageHero {
  blok: PageHeroStoryblok
}

export function PageHero({ blok }: IPageHero) {
  const { dropdownOpen } = useNavContext()

  return (
    <div
      {...storyblokEditable(blok)}
      className={cn({
        'relative flex w-full items-end items-center pb-[40px] pt-[100px] md:min-h-[50vh] md:pb-[40px] lg:min-h-[40vh]':
          true,
        'from-primary-600/20  bg-gradient-to-b to-transparent': !dropdownOpen,
      })}
    >
      <div className="container text-white/70">
        <div className="flex flex-col items-center justify-center text-center">
          {blok.preTitle && (
            <PreTitle
              containerProps={{
                className: 'mb-4',
              }}
            >
              {blok.preTitle}
            </PreTitle>
          )}
          <div className="md:max-w-screen-md">
            <TitleWithAccent
              title={blok.title}
              containerProps={{
                className: 'text-3xl md:text-6xl',
              }}
              tag="h1"
            />
            {blok.text && (
              <RichText
                containerProps={{ className: 'mt-4' }}
                document={blok.text}
              />
            )}
          </div>
          {(blok.buttons?.length ?? 0) > 0 && (
            <div className="mt-4 flex flex-col gap-2 lg:flex-row">
              {blok.buttons!.map((item) => {
                return (
                  <ActionButton
                    {...item}
                    key={item._uid}
                  />
                )
              })}
            </div>
          )}
        </div>
      </div>
      <DotPattern
        width={20}
        height={20}
        cx={1}
        cy={1}
        cr={1}
        className={cn(
          'fill-white/20',
          '[mask-image:linear-gradient(to_bottom_right,white,transparent,transparent)] ',
        )}
      />
    </div>
  )
}
