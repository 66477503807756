'use client'

import { useInfiniteQuery } from '@tanstack/react-query'
import { Button } from '@vendure/components/ui'
import { cn } from '@vendure/helpers'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

interface Contributor {
  login: string
  id: number
  node_id: string
  avatar_url: string
  gravatar_id: string
  url: string
  html_url: string
  followers_url: string
  following_url: string
  gists_url: string
  starred_url: string
  subscriptions_url: string
  organizations_url: string
  repos_url: string
  events_url: string
  received_events_url: string
  type: string
  site_admin: boolean
  contributions: number
}

const PER_PAGE = 50

async function fetchContributors({
  pageParam = 0,
}): Promise<{ data: Contributor[]; nextCursor: number | undefined }> {
  const res = await fetch(
    `https://api.github.com/repos/vendure-ecommerce/vendure/contributors?page=${pageParam}&per_page=${PER_PAGE}`,
  )
  const data = await res.json()
  const filtered = data
    .filter((item: any) => !item.login.includes('github'))
    .filter((item_1: any) => {
      return item_1.login !== 'dlhck' && item_1.login !== 'michaelbromley'
    })

  return {
    data: filtered,
    nextCursor: filtered.length < PER_PAGE - 10 ? undefined : pageParam + 1,
  }
}

export function ContributorsList() {
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery({
    initialPageParam: 1,
    queryFn: fetchContributors,
    queryKey: ['contributors'],
    getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
  })

  return (
    <div className="mx-auto md:max-w-screen-sm">
      {!isLoading && (
        <>
          <div className="grid grid-cols-1 gap-10 md:grid-cols-2">
            {data?.pages.map((page, i) => {
              return (
                <React.Fragment key={i}>
                  {page.data.map((item, idx) => {
                    const pos = idx + 1
                    return (
                      <Link
                        key={item.id}
                        href={item.html_url}
                        target="_blank"
                        className="flex items-center justify-start gap-4"
                      >
                        <div className="relative">
                          {i === 0 && pos <= 3 && (
                            <div
                              className={cn({
                                'bg-primary-400 absolute -left-2 -top-2 flex h-8 w-8 items-center justify-center rounded-full text-center text-sm font-semibold text-white':
                                  true,
                                'bg-[#ffd700]': pos === 1,
                                'bg-[#c7d1da]': pos === 2,
                                'bg-[#88540b]': pos === 3,
                              })}
                            >
                              <span className="drop-shadow-md">{pos}</span>
                            </div>
                          )}
                          <Image
                            className="h-20 w-20 rounded-full object-cover object-center"
                            src={item.avatar_url}
                            alt={item.login}
                            width={200}
                            height={200}
                          ></Image>
                        </div>
                        <div>
                          <p className="font-mono font-bold text-white">
                            {pos === 1 && i === 0 && <span>🏆</span>}{' '}
                            {item.login}
                          </p>
                          <p>{item.contributions} Commits</p>
                        </div>
                      </Link>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </div>
          {hasNextPage && (
            <div className="mt-8 flex justify-center">
              <Button
                variant="secondary"
                onClick={() => fetchNextPage()}
              >
                Load More
              </Button>
            </div>
          )}
        </>
      )}
      {isLoading && <p className="text-center text-xl">Crunching numbers...</p>}
    </div>
  )
}
