'use client'

import type { TextStoryblok } from '@vendure/codegen/storyblok'
import { RichText } from '@vendure/components/shared/client'

interface ICustomText {
  blok: TextStoryblok
}

export function CustomText({ blok }: ICustomText) {
  return <RichText document={blok.text} />
}
