'use client'

import type {
  TestimonialCarouselItemStoryblok,
  TestimonialCarouselStoryblok,
} from '@vendure/codegen/storyblok'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { StoryblokImage } from '@vendure/components/shared/client'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

interface ITestimonialCarousel {
  blok: TestimonialCarouselStoryblok
}

function renderItem(item: TestimonialCarouselItemStoryblok) {
  return (
    <div className="relative flex h-full flex-col items-center justify-center gap-6 py-20 md:gap-8">
      {item.logo?.filename && (
        <StoryblokImage
          alt={item.clientName ?? ''}
          src={item.logo.filename}
          width={400}
          height={0}
          className="h-10 w-auto"
          style={{
            filter: 'brightness(0) invert(1)',
          }}
        />
      )}
      <p className="text-center italic  leading-relaxed text-white md:max-w-screen-md md:text-lg lg:text-2xl">
        {item.testimonial}
      </p>
      <div className="flex flex-col">
        <span className="text-lg font-semibold text-white">{item.name}</span>
        <span>{item.clientName}</span>
      </div>
    </div>
  )
}

export function TestimonialCarousel({ blok }: ITestimonialCarousel) {
  function renderItems() {
    return blok.items?.map((item) => {
      return <SwiperSlide key={item._uid}>{renderItem(item)}</SwiperSlide>
    })
  }

  return (
    <Swiper
      modules={[Pagination, Navigation]}
      slidesPerView={1}
      pagination={{ clickable: true }}
    >
      {renderItems()}
    </Swiper>
  )
}
