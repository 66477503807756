'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import type {
  ArticlesTeaserStoryblok,
  ArticleStoryblok,
} from '@vendure/codegen/storyblok'
import { ArticleCard } from '@vendure/components/shared/client'
import type { StoryblokStory } from 'storyblok-generate-ts'

interface IArticlesTeaser {
  blok: ArticlesTeaserStoryblok
}

export function ArticlesTeaser({ blok }: IArticlesTeaser) {
  const articles = blok.articles as Array<StoryblokStory<ArticleStoryblok>>

  return (
    <div
      className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-10"
      {...storyblokEditable(blok)}
    >
      {articles.map((item, i) => {
        return (
          <ArticleCard
            key={item.uuid}
            article={item}
          />
        )
      })}
    </div>
  )
}
